<template>
  <v-container fluid class="white--text">
    <v-row id="content">
      <v-col cols="12" class="px-1 py-0">
        <h1 v-html="$t('translation.title')"></h1>
      </v-col>
      <v-col cols="12" sm="2" class="pl-2 pr-7 pb-0">
        <v-select
          v-model="language"
          :items="languages"
          item-text="title"
          item-value="locale"
          :label="$t('translation.languageLabel')"
          @change="languageChanged"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3" class="pl-2 pr-7 pb-0">
        <v-select
          v-model="page"
          :items="pages"
          item-text="title"
          item-value="id"
          :label="$t('translation.pageLabel')"
          @change="pageChanged"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="7" class="pa-0 px-3 pb-0">
        <v-row>
          <v-col cols="6" sm="3" class="px-1" align="right">
            <v-btn
              depressed
              tile
              v-html="$t('translation.cancelButton')"
              class="primary white--text full-width"
              :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
              @click="cancel()"
            ></v-btn>
          </v-col>
          <v-col cols="6" sm="3" class="px-1" align="right">
            <v-btn
              depressed
              tile
              v-html="$t('translation.saveButton')"
              class="primary white--text full-width"
              :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
              @click="save()"
            ></v-btn>
          </v-col>
          <v-col cols="6" sm="3" class="px-1" align="right">
            <v-btn
              depressed
              tile
              v-html="$t('translation.reloadButton')"
              class="primary white--text full-width"
              :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
              @click="reload()"
            ></v-btn>
          </v-col>
          <v-col cols="6" sm="3" class="px-1" align="right">
            <v-btn
              depressed
              tile
              v-html="$t('translation.previewButton')"
              class="primary white--text full-width"
              :class="{
                'mt-3 mb-0': $vuetify.breakpoint.mdAndUp,
                secondary: preview,
              }"
              @click="preview = !preview"
            ></v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" :sm="preview ? 6 : 12" class="px-1 pb-0">
        <v-col>
          <v-row>
            <v-col cols="6" class="pa-0">
              <v-text-field
                v-model="editedItem.title"
                :label="$t('translation.titleLabel')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pa-0 pl-3">
              <v-text-field
                v-model="editedItem.videos"
                :label="$t('translation.videosLabel')"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pa-0">
          <v-textarea
            v-model="editedItem.content"
            auto-grow
            outlined
            :label="$t('translation.contentLabel')"
            class="font-small"
          ></v-textarea>
        </v-col>
      </v-col>
      <v-col v-if="preview" cols="12" sm="6" class="px-1 pb-0">
        <v-col class="pa-0">
          <h2>{{ $t("translation.previewLabel") }}</h2>
        </v-col>
        <v-col class="pa-0">
          <v-card flat class="pa-3">
            <div v-html="contentPreview"></div>
          </v-card>
        </v-col>
      </v-col>
    </v-row>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      pages: [],
      page: null,
      item: {
        id: null,
        title: null,
        content: null,
      },
      editedItem: {
        id: null,
        title: null,
        content: null,
      },
      language: null,
      loading: false,
      preview: true,
    };
  },

  computed: {
    ...mapGetters(["languages", "axiosSource"]),
    contentPreview() {
      let text = "<h1>" + this.editedItem.title + "</h1>";

      const videosContent = this.getVideosContentPreview();

      if (videosContent != undefined && videosContent.length > 0) {
        text += videosContent;
      }

      text += this.editedItem.content;

      if (this.editedItem.content) {
        return text;
      } else {
        return "";
      }
    },
  },

  methods: {
    setLanguage(locale) {
      const lang = this.languages.find((item) => item.locale === locale);

      if (lang) {
        this.language = lang;
      }
    },
    languageChanged(locale) {
      this.setLanguage(locale);
      this.getDictionaryPages(locale);
    },
    getDictionaryPages(locale) {
      this.loading = true;

      this.$store.dispatch("getDictionaryPages", locale).then(
        (response) => {
          this.loading = false;

          this.pages = response.items;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.getDictionaryStatic(this.language.locale, this.page);
        },
        (error) => {
          this.loading = false;

          this.pages = [];
          this.item = {};
          this.editedItem = {};

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    pageChanged(page) {
      this.getDictionaryStatic(this.language.locale, page);
    },
    getDictionaryStatic(locale, page) {
      if (!locale || !page) {
        return;
      }

      this.loading = true;

      this.$store
        .dispatch("getDictionaryStatic", {
          locale: locale,
          page: page,
        })
        .then(
          (response) => {
            this.loading = false;

            this.item = {
              id: response.item.DictionaryStaticID,
              title: response.item.Title,
              videos: response.item.Videos,
              content: response.item.Content,
            };

            this.editedItem = JSON.parse(JSON.stringify(this.item));

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);
          },
          (error) => {
            this.loading = false;

            this.item = {};
            this.editedItem = {};

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);
          }
        );
    },
    cancel() {
      this.editedItem = JSON.parse(JSON.stringify(this.item));
    },
    save() {
      this.loading = true;

      this.$store
        .dispatch("updateDictionaryStatic", {
          id: this.editedItem.id,
          title: this.editedItem.title,
          videos: this.editedItem.videos,
          content: this.editedItem.content,
        })
        .then(
          (response) => {
            this.loading = false;

            this.item = {
              id: response.item.DictionaryStaticID,
              title: response.item.Title,
              videos: response.item.Videos,
              content: response.item.Content,
            };

            this.editedItem = JSON.parse(JSON.stringify(this.item));

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);
          },
          (error) => {
            this.loading = false;

            this.item = {};
            this.editedItem = {};

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);
          }
        );
    },
    reload() {
      location.reload();
    },
    getVideosContentPreview() {
      if (
        this.editedItem.videos != undefined &&
        this.editedItem.videos.length > 0
      ) {
        let content = "";

        const arr = this.editedItem.videos.split(",");
        let url = "";

        arr.forEach((id) => {
          url = "https://player.vimeo.com/video/" + id.replace(/"/g, "");
          content +=
            "<iframe title='vimeo-player' src='" +
            url +
            "' frameborder='0' allowfullscreen ></iframe>";
        });

        return content;
      } else {
        return "";
      }
    },
  },

  created() {
    this.setLanguage("en");

    if (this.language) {
      this.getDictionaryPages(this.language.locale);
    }
  },
};
</script>

<style lang="scss" scoped>
.font-small {
  font-size: 14px;
}
</style>
